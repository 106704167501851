import React from "react";
import { Routes, Route, useLocation } from 'react-router-dom';

import Main from "./main";
import Store from './store';
import Products from "./products";
import Details from "./details";

import { AnimatePresence } from 'framer-motion';

function Routescomponent() {
    const location = useLocation();

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path='/' element={<Main />} />
                <Route path='/:id' element={<Store />} />
                <Route path='/:storeid/category/:categoryid' element={<Products />} />
                <Route path='/:storeid/product/:productid' element={<Details />} />
                <Route path='*' />
            </Routes>
        </AnimatePresence>
    );
}

export default Routescomponent