import React, { Component } from 'react'
import { useParams } from 'react-router-dom';

import { setGlobalState, useGlobalState } from '../../globalStates/store';

import LoadingKanna from '../loading/kannaload';
import AdComponent from '../ads/googleads';

import Theme1 from '../theme1';
import Theme2 from '../theme2';

class Store extends Component {

    constructor(props) {
        super(props);
        this.state = {
            store: [],
            isStoreLoaded: false,
            redirect: false,
        };
    }

    componentDidMount() {

        const { id } = this.props.params;
        const storeinfo = this.props.storeinfo;

        if (storeinfo.code !== undefined && storeinfo.code != "") {
            console.log("running local data");
            this.setState({ 
                isStoreLoaded: true,
                store: storeinfo,
            });
        }
        else {
            console.log("running api data");
            fetch('https://emenu.libertas-io.com/api/store/' + id)
                .then(res => res.json())
                .then(json => {

                    if (json.success === 1) {
                        this.setState({
                            isStoreLoaded: true,
                            store: json.data,
                        });

                        setGlobalState("storeinfo", json.data);
                    }
                    else {
                        this.setState({
                            redirect: true,
                        });
                    }
            });
        }

    }

    render() {

        var { store, isStoreLoaded, redirect } = this.state;
        if (redirect) {
            return <div>No Exist</div>;
        }

        if (isStoreLoaded) {
        return (
            <Theme1 name={store.name} code={store.code} logo={store.logo} cover={store.cover} address1={store.address_1} address2={store.address_2} city={store.city} postcode={store.postcode} tel={store.contact_1}></Theme1>
            // <Theme2 name={store.name} code={store.code} logo={store.logo} address1={store.address_1} address2={store.address_2}></Theme2>
            
            );
        }

        return (
            <LoadingKanna />
        )
    }
}


export default (props) => {
    const [storeinfo] = useGlobalState("storeinfo");
    return <Store {...props} params={useParams()} storeinfo={storeinfo} />;
}