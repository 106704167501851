import React from "react";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

const Main = props => {

    const navigate = useNavigate()

    useEffect(() => {
        navigate("/demo")
    })

    return (
        <div></div>
    )
}

export default Main;