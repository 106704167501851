import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useGlobalState, setGlobalState } from "../../globalStates/store";

import Theme1Details from "../theme1/details";
import Theme2Details from "../theme2/details";

const Details = props => {

    let navigate = useNavigate();

    let { storeid, productid } = useParams();
    const [storeinfo] = useGlobalState("storeinfo");
    const [products] = useGlobalState("products");

    const [productinfo, setProductinfo] = useState({});
    const [isallow, setAllow] = useState(false);

    useEffect(() => {
        if (!(storeinfo.code !== undefined && storeinfo.code == storeid)) {
            navigate("/" + storeid);
        }
        else {
            setProductinfo(products.filter(x => x.id == productid)[0]);
            setAllow(true);
        }

    }, []);

    return (
        isallow &&
            // <Theme1Details StoreCode={storeid} Products={productinfo} />
            <Theme2Details StoreCode={storeid} Products={productinfo} />
        
    )
}

export default Details;