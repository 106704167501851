import React from "react";

const CategoryLoading = props => {
    return (
        <>
            <div className="px-2 py-3 flex flex-row justify-start cursor-pointer">
                    <div className="w-[4.375rem] h-[4.375rem] min-w-[4.375rem] bg-slate-200 animate-pulse">
                    
                    </div>
                    <div className="ml-3 flex items-center whitespace-nowrap overflow-hidden text-ellipsis animate-pulse">
                        <span className="text-xl font-bold lexend bg-slate-200 h-3 w-40 rounded-xl">
                        
                        </span>
                    </div>
                    <div className="pl-5 pr-2 flex items-center min-w-[1rem] ml-auto">
                        <span className="material-symbols-outlined">
                            chevron_right
                        </span>
                    </div>
                </div>

                <div className="px-2 py-3 flex flex-row justify-start cursor-pointer">
                    <div className="w-[4.375rem] h-[4.375rem] min-w-[4.375rem] bg-slate-200 animate-pulse">
                    
                    </div>
                    <div className="ml-3 flex items-center whitespace-nowrap overflow-hidden text-ellipsis animate-pulse">
                        <span className="text-xl font-bold lexend bg-slate-200 h-3 w-40 rounded-xl">
                        
                        </span>
                    </div>
                    <div className="pl-5 pr-2 flex items-center min-w-[1rem] ml-auto">
                        <span className="material-symbols-outlined">
                            chevron_right
                        </span>
                    </div>
                </div>

                <div className="px-2 py-3 flex flex-row justify-start cursor-pointer">
                    <div className="w-[4.375rem] h-[4.375rem] min-w-[4.375rem] bg-slate-200 animate-pulse">
                    
                    </div>
                    <div className="ml-3 flex items-center whitespace-nowrap overflow-hidden text-ellipsis animate-pulse">
                        <span className="text-xl font-bold lexend bg-slate-200 h-3 w-40 rounded-xl">
                        
                        </span>
                    </div>
                    <div className="pl-5 pr-2 flex items-center min-w-[1rem] ml-auto">
                        <span className="material-symbols-outlined">
                            chevron_right
                        </span>
                    </div>
                </div>
        </>
    )

}

export default CategoryLoading