import React from "react";

class AdComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount () {
        const adsbygoogle = window.adsbygoogle;
        console.log(adsbygoogle);
        adsbygoogle.push({});
    }
  
  render () {
      return (
        <ins className="adsbygoogle"
        style={{display : 'block'}}
        data-ad-client="ca-pub-3691611750210161"
        data-ad-slot="6427852255"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
        );
    }
  }
  
export default AdComponent;