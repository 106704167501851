import React from "react";

import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { Helmet } from 'react-helmet';

import Category from "./categories/category";

const Theme1 = props => {

    return (
        <motion.div 
                    className="min-h-screen"
                    initial={{ x : -window.innerWidth }}
                    animate={{ x : 0 }}
                    exit={{ x : -window.innerWidth }} 
                    transition={{ duration : 0.5 }}>
                    <Helmet>
                        <title>{props.name}</title>
                    </Helmet>
                    <div className="relative overflow-hidden bg-white pb-5">
                        <div className="absolute top-0 h-[230px] w-full p-3">
                            <div className="top-0 bottom-0 left-0 right-0 h-full w-full">
                                {
                                    props.code == "itzhaji" ? (
                                        <img src={"https://i.imgur.com/PDvQnlM.png"} className="h-full w-full object-cover rounded-xl" alt="" />
                                    )
                                    :
                                    (
                                        <img src={props.cover} className="h-full w-full object-cover rounded-xl" alt="" />
                                    )
                                }
                                {/* <LazyLoadImage src={"https://media-cdn.tripadvisor.com/media/daodao/photo-s/0e/57/31/b5/caption.jpg"} className="h-full w-full object-cover rounded-xl" alt="" effect='opacity' /> */}
                            </div>
                        </div>

                        <div className="relative flex mt-[180px] bg-white">
                            <div className="basis-1/3 flex justify-end">
                                <div className="w-[8.5rem] h-[8.5rem] mt-[-3.125rem] ml-4 p-1 rounded-full bg-white">
                                    {/* <LazyLoadImage src={store.logo} className="h-full w-full rounded-full" alt="" effect='opacity' /> */}
                                    <img src={props.logo} className="h-full w-full rounded-full" alt="" effect='opacity' />
                                </div>

                            </div>
                            <div className="mt-2 ml-1 basis-2/3 pr-3">
                                <span className="text-xl font-bold lexend tracking-tight">
                                    {props.name}
                                </span>
                                {/* <span className="block text-xs font-bold lexend tracking-tight text-stone-700">
                                    测试
                                </span> */}
                                <span className="block text-xs lexend tracking-tight text-stone-400">
                                    {`${props.address1 ?? ""} ${props.address2 ?? ""}`}
                                    <br />
                                    {`${props.city ?? ""} ${props.postcode ?? ""}`}
                                    <br />
                                    {`Tel : ${props.tel ?? ""}`}
                                </span> 
                            </div>

                        </div>
                    </div>

                    <Category />

                </motion.div>
    )

}

export default Theme1;