import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { motion } from 'framer-motion';
import { HiArrowLeft } from 'react-icons/hi';

import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu"
import usePreventBodyScroll from "../../../usePreventBodyScroll"

import { GrClose, GrFormClose } from 'react-icons/gr';


import { useGlobalState, setGlobalState } from "../../../globalStates/store";

const Theme2Details = props => {

    let navigate = useNavigate();

    let { storeid, productid } = useParams();
    const [storeinfo] = useGlobalState("storeinfo");
    const [product] = useGlobalState("products");

    const [productinfo, setProductinfo] = useState({});
    const [fullWidth, setFullWidth] = useState(0);
    const [sitems, setsitems] = useState([]);
    const divImg = useRef(null);

    const { disableScroll, enableScroll } = usePreventBodyScroll();

    // console.table(suggestItem);

    // suggestItem = getRandomProduct(suggestItem,10);


    useEffect(() => {

        setFullWidth(divImg.current.offsetWidth);

        const filterItem = product.filter(x => x.id !== props.Products.id);
        const suggestItem = getRandomProduct(filterItem, 10);
        setsitems(suggestItem);

        window.scrollTo(0, 0);

    }, []);

    function getRandomProduct(arr, n) {
        var result = new Array(n),
            len = arr.length,
            taken = new Array(len);
        if (n > len)
            n = len;
        while (n--) {
            var x = Math.floor(Math.random() * len);
            result[n] = arr[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
        }
        return result;
    }

    return (
        <motion.div
            className="min-h-screen bg-zinc-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4 }}>
            <div className="fixed top-3 left-5 bg-zinc-50 p-2 rounded-full cursor-pointer" onClick={() => { navigate(-1) }}>
                <HiArrowLeft className="text-xl text-black" />
            </div>
            <div className="flex justify-center items-center overflow-hidden w-full aspect-square" ref={divImg}>
                <LazyLoadImage src={props.Products.product_image[0].url} alt={props.Products.name} width={fullWidth} className="min-w-full min-h-full object-cover" effect="opacity" />
            </div>
            <div className="relative w-full bg-zinc-50 px-6">
                <span className="block pt-6 font-bold tracking-wide">{props.Products.c_name}</span>
                <span className="block font-bold tracking-wide">{props.Products.name}</span>
                <span className="block py-6 font-bold tracking-wide">RM {props.Products.price.toFixed(2)}</span>
            </div>
            <div className="relative w-full bg-white px-10 py-6">
                <span className="block tracking-wider text-sm text-emerald-500">Description</span>
                <span className="block tracking-wider text-black mt-2">{props.Products.description}</span>
                <span className="block tracking-wide text-zinc-700 mt-6 font-bold text-sm">Product Code - <span className=" font-normal">{props.Products.product_code}</span></span>
            </div>
            <div className="pt-6 py-12">
                <span className="block font-bold tracking-wide ml-6">You may also like</span>
                <div className="pt-2" onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
                    <ScrollMenu onWheel={onWheel}>
                        {
                            sitems.map((item, index) => (
                                <div className="ml-6" onClick={() => { navigate("/"+ props.StoreCode + "/product/"+ item.id) }}>
                                    <div className="w-40 aspect-square bg-zinc-200">
                                        <LazyLoadImage alt={item.name} src={item.product_image[0].url} className="w-full h-full object-cover" effect="opacity" />
                                    </div>
                                    <span className="block text-xs tracking-wide">{item.name}</span>
                                    <span className="block text-xs tracking-wide leading-4">RM {(Math.round(item.price * 100) / 100).toFixed(2)}</span>
                                </div>

                            ))
                        }
                    </ScrollMenu>
                </div>
            </div>
        </motion.div>
    )

}

function onWheel(apiObj, ev) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
}

export default Theme2Details    