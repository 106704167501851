import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';

import { HiArrowLeft } from 'react-icons/hi';

import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu"
import usePreventBodyScroll from "../../../usePreventBodyScroll"

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const Theme2Products = props => {


    let navigate = useNavigate();

    const divHeader = useRef(null);
    const [headerHeight, setHeaderheight] = useState(0);

    const { disableScroll, enableScroll } = usePreventBodyScroll();

    var apiRef = React.useRef(VisibilityContext);

    useEffect(() => {

        setHeaderheight(divHeader.current.clientHeight);

        if (props.CatName) {
            var idx = -1;
            var filter = props.CategoryList.find(function (item, i) {
                if (item.name == props.CatName) {
                    idx = i;
                    return i;
                }
            });

            apiRef.current.scrollToItem(apiRef.current.getItemElementByIndex(idx));
        }

    }, [props.CatName, props.CategoryList]);

    return (
        <motion.div
            className="min-h-screen bg-white"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }} >
            <div className="relative bg-white">
                <div className="flex py-3 bg-white fixed top-0 w-full z-10" ref={divHeader}>
                    <div className="px-5 min-w-[1rem] flex items-center hover:cursor-pointer" onClick={() => { navigate("/" + props.StoreCode) }}>
                        <HiArrowLeft className="text-xl text-slate-600" />
                    </div>
                    <div className="grow text-center">
                        <span className="text-2xl font-bold tracking-wide">
                            {props.CatName}
                        </span>
                    </div>
                    <div className="px-5 min-w-[1rem]">

                    </div>
                </div>
                <div style={{ height: headerHeight + 'px' }}></div>
                <div className="pt-3 pb-4" onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
                    <ScrollMenu onWheel={onWheel} apiRef={apiRef}>
                        {props.CategoryList.map((item, index) => (
                            item.name == props.CatName ? (  
                                <div key={"catSelected"} className="px-5 py-1 ml-3 rounded-3xl border border-violet-700 bg-violet-700 whitespace-nowrap cursor-pointer">
                                    <span className=" text-[0.75rem] text-white font-semibold leading-none">{item.name}</span>
                                </div>
                            ) :
                                (
                                    <div key={"cat-" + index} className="px-5 py-1 ml-3 rounded-3xl bg-white border border-black whitespace-nowrap cursor-pointer" 
                                    onClick={() => { navigate("/"+ props.StoreCode + "/category/"+ item.id) }}>
                                        <span className="text-[0.75rem] text-black font-semibold leading-none">{item.name}</span>
                                    </div>
                                )
                        ))}
                    </ScrollMenu>
                </div>
                <div className="grid grid-cols-2 gap-2 px-3">
                    {
                        props.ProductList.map((item, index) => (
                            <div className="w-full relative block mb-10 cursor-pointer" onClick={() => { navigate("/"+ props.StoreCode + "/product/"+ item.product.id) }}>
                                <div className="relative w-full aspect-square">
                                    <LazyLoadImage src={item.product.product_image[0].url} alt={item.product.name} effect="opacity" className="bg-white" />
                                    <div className="absolute bottom-5 left-0 bg-amber-100 py-1 px-2 text-[0.75rem] text-black">
                                        RM {(Math.round(item.product.price * 100) / 100).toFixed(2)}
                                    </div>
                                </div>
                                <div className="py-4">
                                    <span className="block font-semibold text-sm leading-5">{item.product.name}</span>
                                    <span className="block font-semibold text-sm leading-5">{(Math.round(item.product.price * 100) / 100).toFixed(2)}</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

        </motion.div>
    )

}

function onWheel(apiObj, ev) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
}

export default Theme2Products