import React from "react";
    import loadingimg from '../../img/KannaSip.png'

import { motion } from 'framer-motion'

const LoadingKanna = props => {

    return(

        <motion.div
         className="w-full h-screen flex flex-col justify-center items-center"
         initial={{ x : -window.innerWidth }}
         animate = {{ x : 0 }} 
         exit={{ x : window.innerWidth }}
         transition={{ duration : 0.5 }} >
            <img src={loadingimg} className="w-20 h-20" alt="" />
            <span className="lexend text-lg mt-5">Loading . . .</span>
        </motion.div>

    )

}
 
export default LoadingKanna