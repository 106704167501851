import React, { useEffect, useState } from "react";
import Categories from "./categories";

import { useGlobalState, setGlobalState } from "../../../globalStates/store";

import Categoriesitem from "./categories";
import CategoryLoading from './categories-load';

const Category = props => {

    const [storeinfo] = useGlobalState("storeinfo")
    const storeid = storeinfo.code

    const [localcategories] = useGlobalState("categories")

    const [categories, setCategories] = useState([])
    const [isloaded, setIsLoaded] = useState(false)
    const [isloadedProducts, setIsLoadedProducts] = useState(false)

    useEffect(() => {
        if(localcategories.length > 0)
        {
            setCategories(localcategories)
            setIsLoaded(true)
            setIsLoadedProducts(true)
        }
        else
        {
            fetch('https://emenu.libertas-io.com/api/categories/' + storeid)
            .then(res => res.json())
            .then(json => {

                if (json.success === 1) {
                    setCategories(json.data)
                    setGlobalState("categories",json.data)
                    setIsLoaded(true)
                }
            })

            fetch('https://emenu.libertas-io.com/api/products/' + storeid)
            .then(res => res.json())
            .then(json => {

                if (json.success === 1) {
                    setGlobalState("products",json.data)
                    setIsLoadedProducts(true)
                }
            })

        }
    }, [])

    return (
        <div className="relative overflow-hidden bg-white mt-6">
            <div className="px-6 pt-1">
                <span className="text-xs font-bold lexend">
                    Explore our menu
                </span>
            </div>
            {
                isloaded && isloadedProducts ? (
                    categories.map((item, index) => (
                        <div key={index}>
                            <Categoriesitem id={item.id} name={item.name} img={item.image} />
                            {index < categories.length - 1 && <hr />}
                        </div>
                    ))
                )
                    :
                    (
                        <CategoryLoading />
                    )
            }
        </div>
    )

}

export default Category