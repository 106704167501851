import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router } from 'react-router-dom';

import Routescomponent from './pages/routescomponents';

function App() {

  return (
    <div className="mobile-layout bg-stone-50 h-screen safearea">
      <Router>
        <Routescomponent />
      </Router>
    </div>
  );
}

export default App;
