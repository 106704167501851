import React, {useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import { useGlobalState, setGlobalState } from "../../globalStates/store";

import Theme1Products from "../theme1/products";
import Theme2Products from "../theme2/products";

const Products = props => {

    let navigate = useNavigate();

    let { storeid, categoryid } = useParams();
    const [storeinfo] = useGlobalState("storeinfo");
    const [categories] = useGlobalState("categories");

    const [categoryname, setCategoryname] = useState("");
    const [productlist, setProductlist] = useState([]);

    useEffect(() => {
        if (!(storeinfo.code !== undefined && storeinfo.code == storeid)) {
            navigate("/" + storeid);
        }
        else {
            setCategoryname(categories.filter(x => x.id == categoryid)[0].name);
            setProductlist(categories.filter(x => x.id == categoryid)[0].product_category_mapping);
        }

    }, []);

    return(
        // <Theme1Products CatName={categoryname} ProductList={productlist} StoreCode={storeid} />
        <Theme2Products CatName={categoryname} CategoryList={categories} ProductList={productlist} StoreCode={storeid} />
    )

}

export default Products