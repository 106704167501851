import React, { useEffect, useState } from "react"
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu"

import { useGlobalState, setGlobalState } from "../../../globalStates/store"

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import loadingImg from "./../../../img/loadingdog.gif"

import { motion } from "framer-motion"
import usePreventBodyScroll from "../../../usePreventBodyScroll"

import '../stylesheet/category.css'

import { useNavigate } from "react-router-dom"

const Theme2Category = props => {

    let navigate = useNavigate();

    const [storeinfo] = useGlobalState("storeinfo")
    const storeid = storeinfo.code

    const [localcategories] = useGlobalState("categories")

    const [categories, setCategories] = useState([])
    const [isloaded, setIsLoaded] = useState(false)
    const [isloadedProducts, setIsLoadedProducts] = useState(false)

    const { disableScroll, enableScroll } = usePreventBodyScroll();

    useEffect(() => {
        if (localcategories.length > 0) {
            setCategories(localcategories)
            setIsLoaded(true)
            setIsLoadedProducts(true)
        }
        else {
            fetch('https://emenu.libertas-io.com/api/categories/' + storeid)
                .then(res => res.json())
                .then(json => {

                    if (json.success === 1) {
                        setCategories(json.data)
                        setGlobalState("categories", json.data)
                        setIsLoaded(true)
                    }
                })

            fetch('https://emenu.libertas-io.com/api/products/' + storeid)
                .then(res => res.json())
                .then(json => {

                    if (json.success === 1) {
                        setGlobalState("products", json.data)
                        setIsLoadedProducts(true)
                    }
                })

        }
    }, [])

    return (

        <div className="relative bg-white pb-10 min-h-full">

                        <div className="w-full flex flex-row justify-start items-center pl-6 mb-6 border-b py-4 border-b-black">
                            <div className="w-16 aspect-square rounded-full bg-white">
                                {/* <LazyLoadImage src={store.logo} className="h-full w-full rounded-full" alt="" effect='opacity' /> */}
                                <img src={props.logo} className="h-full w-full rounded-full" alt="" effect='opacity' />
                            </div>
                            <div className="flex flex-col mx-2">
                                <span className="font-sans font-extrabold text-lg text-slate-700 leading-tight tracking-tight">{props.name.toUpperCase()}</span>
                                <span className="lexend font-bold text-[0.65rem] text-slate-400">{"Short Description Here".toUpperCase()}</span>
                            </div>
                        </div>

                        <div className="pl-6">
                            <span className="text-lg font-bold tracking-wide">Trending categories</span>
                        </div>
                        <div className="mt-4 px-6" onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
                            <ScrollMenu onWheel={onWheel}>
                                {categories.map((item, index) => (
                                    <div className="flex flex-col items-center cursor-pointer text-center" onClick={() => { navigate("/"+ storeid + "/category/"+ item.id) }}>
                                        <div className="w-24 aspect-square rounded-full overflow-hidden mx-2 shadow-lg">
                                            {/* <img src={item.image} alt={item.name} className="h-full w-full object-cover" /> */}
                                            <LazyLoadImage src={item.image} alt={item.name} className="h-full w-full object-cover" effect="opacity" />
                                        </div>
                                        <div className="mt-2"><span className="font-bold">{item.name}</span></div>
                                    </div>
                                ))}
                            </ScrollMenu>
                        </div>
                        <div className="flex flex-col items-center border-b-black border-b pb-12">
                            {categories.map((item, index) => (
                                <motion.span 
                                initial={{opacity : 0}}
                                animate={{opacity : 1}}
                                transition={{duration : 1, delay : (index * 0.2 + 0.2)}}
                                className="text-4xl capitalize py-4 mt-4 cursor-pointer"
                                onClick={() => { navigate("/"+ storeid + "/category/"+ item.id) }}>{item.name}</motion.span>
                            ))}
                        </div>
                    </div>
    )

}

function onWheel(apiObj, ev) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
}

export default Theme2Category