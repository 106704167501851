import React from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../../../globalStates/store";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { BiChevronRight } from 'react-icons/bi'

const Categoriesitem = props => {

    const [storeinfo] = useGlobalState("storeinfo");
    const storeid = storeinfo.code;
    let navigate = useNavigate();

    return (

        <div className="px-2 py-3 flex flex-row justify-start cursor-pointer" onClick={() => { navigate("/"+ storeid + "/category/"+ props.id) }}>
                <div className="w-[4.375rem] h-[4.375rem] min-w-[4.375rem]">
                    <LazyLoadImage src={props.img} className="h-full w-full" alt="" effect="opacity" />
                </div>
                <div className="ml-3 flex items-center whitespace-nowrap overflow-hidden text-ellipsis">
                    <span className="text-xl font-bold lexend">
                        {props.name}
                    </span>
                </div>
                <div className="pl-5 pr-2 flex items-center min-w-[1rem] ml-auto">
                    {/* <span className="material-symbols-outlined">
                        chevron_right
                    </span> */}
                    <BiChevronRight size={24} />
                </div>
            </div>
    )

}

export default Categoriesitem